import React, { useEffect, useRef, useState } from "react";
import "./BlogBanner.css";
import {
  Button,
  Container,
  InputBase,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { useDispatch } from "react-redux";
const BlogBanner = ({title}) => {
  const [searchVal, setSearchVal] = useState(null);
  const inputSearchRef = useRef();
  const navigate = useNavigate();
  const submitSearch = (e) => {
    e.preventDefault();
    if (searchVal !== "") {
      navigate(`/search/${searchVal}`);
    }
  };

  const submitSearchValue = (e) => {
    e.preventDefault();
    if (inputSearchRef.current.value) {
      // navigate(`/search/${inputSearchRef.current.value}`);
      navigate(`/shop?search=${inputSearchRef.current.value}`);
    }
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      marginTop: "1.8rem",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        "&:focus": {
          width: "100%",
        },
      },
    },
  }));

  useEffect(() => {
    const videoElement = document.getElementById("video-about");
    if (videoElement) {
      videoElement.play();
    }
  }, []);

  return (
    <>
      <div className="abt-sec-1" style={{ width: "100%" }}>
        <Container>
          <div className="abt-sec-1-content">
            <Typography variant="h1" margin={"1rem 0"} className="mont-title">
                DIZEL Marketplace Built for Contractors
            </Typography>
            <Typography variant="h3" margin={"1rem 0"}>
              Rent, Buy and List Equipment with Ease
            </Typography>
            {/* <form class="search" onSubmit={(e) => submitSearchValue(e)}>
              <Search>
                <SearchIconWrapper>
                  <SearchRounded />
                </SearchIconWrapper>
                <StyledInputBase
                  inputRef={inputSearchRef}
                  placeholder="Search Equipment…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </form> */}
          </div>
        </Container>
      </div>
    </>
  );
};

export default BlogBanner;
