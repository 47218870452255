import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import BlogBanner from "../components/BlogBanner/BlogBanner";

const Article3 = () => {
//   const [data, setData] = useState(
//     `${process.env.PUBLIC_URL}/assets/rental-terms.pdf`
//   );
  return (
    <>
    <BlogBanner/>
    <Container
      maxWidth="md"
      sx={{ textAlign: "justify", marginBottom: "5rem" }}
    >
      <Box data-aos="fade-in" data-aos-duration={500}>
        <Typography
          variant="h1"
          textAlign={"left"}
          fontWeight={700}
          component="h1"
          gutterBottom
          marginTop={"2rem"}
        >
          Top 5 ways to put idle construction machinery back to work effectively
        </Typography>
        <>
        <div>

      <p style={{ lineHeight: '1.38', margin: '12pt 0' }} dir="ltr">
        Idle construction machinery can be costly to keep around, but there are smart ways to put it back to work. Here are five effective strategies:
      </p>

      <ol style={{ paddingInlineStart: '48px' }}>
        <li>
          <strong>Rent It Out</strong>: When your construction equipment isn’t being used, renting it out to other companies can help generate income. Many contractors need equipment for short-term projects and would rather rent than buy. This is an easy way to put your machinery to use and earn money at the same time.
        </li>
        <li>
          <strong>Offer It for Smaller Local Jobs</strong>: Not all construction projects are large-scale. Smaller, local jobs, like driveway paving or garden landscaping, often need machinery. Reach out to homeowners or small businesses in your community to see if they need help with these kinds of tasks.
        </li>
        <li>
          <strong>Partner with Other Contractors</strong>: You can team up with other contractors who may have jobs lined up but lack the necessary machinery. This allows your equipment to be used on a shared basis, helping both parties to complete jobs and benefit financially.
        </li>
        <li>
          <strong>Maintain It for Future Use</strong>: While not a direct way to put machinery to work, ensuring that your equipment is well-maintained will make it ready for action when the need arises. Regular maintenance can extend the lifespan of your machinery and ensure it’s working properly when opportunities come up.
        </li>
        <li>
          <strong>Use It for Training</strong>: If you’re not currently using the equipment on job sites, consider using it to train new employees. This helps them get hands-on experience and ensures your workforce is well-prepared for future projects.
        </li>
      </ol>

      <p>
        At <strong>Dizel</strong>, we help you implement these smart strategies to get your idle construction equipment back into action. Whether it's renting out machinery, connecting with local projects, partnering with contractors, ensuring proper maintenance, or using equipment for employee training, we provide the solutions to maximize your resources effectively.
      </p>


        </div>

        </>

      </Box>
    </Container>
    </>
  );
};

export default Article3;
